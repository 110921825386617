* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
   font-family: 'Encode Sans Expanded', sans-serif;
}

html {
  scroll-behavior: smooth;
}

/* font-family: 'Alegreya Sans', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,300;0,400;0,500;0,700;0,800;1,300;1,400;1,500;1,700;1,800&display=swap');

/* font-family: 'IBM Plex Sans', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

/* font-family: 'Neuton', serif; */
@import url('https://fonts.googleapis.com/css2?family=Neuton:ital,wght@0,300;0,400;0,700;0,800;1,400&display=swap');

/* font-family: 'Crimson Text', serif; */
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

/* font-family: 'Source Sans Pro', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap');

/* font-family: 'Roboto', sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');


:root {

  /* Main Colors */

  --clr-amber: #8C291B;
  --clr-light-gray: #FBFCFC;
  --clr-mid-gray: #F9FAFB;
  --clr-dark-gray: #E0E1E1;
  --clr-cream: #FFFAF2;
  --clr-off-black: #212121;


  /* Font */

  --ff-title: 'Crimson Text', serif;
  --ff-primary: 'Encode Sans Expanded', sans-serif;
  --ff-default: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  /* General */
  --transition: all 0.3s linear;
  --spacing: 2px;
  --radius: 3px;
  --max-width: 1170px;

} 